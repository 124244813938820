import { Component, OnInit, NgZone, ChangeDetectorRef } from '@angular/core';
import {Organization} from '../../models/organization';
import {User} from '../../models/user';
import {UserService} from '../../user.service';
import {ApiService} from '../../api.service';

@Component({
    selector: 'app-dashboard',
    templateUrl: 'profile.component.html'
})
export class ProfileComponent implements OnInit {

    user: User;
    organizations: Organization[];

    constructor(private userService: UserService, private apiService: ApiService) {}

    async ngOnInit() {
        this.organizations = [];
        this.user = await this.userService.getUserPromise();
        if (this.user.organization) {
          this.organizations = await this.apiService.getOrganizations().toPromise();
        }
    }

    async onOrganizationChange(organization: Organization) {
      this.user.organization = organization
      this.user = await this.apiService.updateUsers([this.user]).toPromise();
    }
}
