import { Component, OnDestroy, Inject, NgZone } from '@angular/core';
import { Router } from '@angular/router';
import { DOCUMENT } from '@angular/common';
import { navItems } from './../../_nav';
import {saNavItems} from '../../_sanav';
import {paNavItems} from '../../_panav';
import {User} from '../../models/user';
import {UserService} from '../../user.service';
import {OAuthService} from 'angular-oauth2-oidc';


@Component({
  selector: 'app-dashboard',
  templateUrl: './default-layout.component.html'
})
export class DefaultLayoutComponent implements OnDestroy {
  public navItems = [];
  public sidebarMinimized = true;
  private changes: MutationObserver;
  public element: HTMLElement;
  public avatarUrl = 'assets/img/avatars/generic.jpg';

  protected navbarBrandFull = null;
  // {src: 'http://rightheightmfg.com/wp-content/uploads/2011/12/RightHeightLogo1.png', width: 33, height: 27, alt: ''};
  protected navbarBrandMinimized = null;
  protected navbarBrandText = {icon: '', text: ''};

  constructor(private router: Router, private userService: UserService, private oAuthService: OAuthService,
              @Inject(DOCUMENT) _document?: any) {

    this.changes = new MutationObserver((mutations) => {
      this.sidebarMinimized = _document.body.classList.contains('sidebar-minimized');
    });
    this.element = _document.body;
    this.changes.observe(<Element>this.element, {
      attributes: true,
      attributeFilter: ['class']
    });

    this.checkUser();

  }

  async checkUser() {
    let defaultPage;
    const user: User = await this.userService.getUserPromise();
    if (user.image) {
      this.avatarUrl = 'https://lifedesk.app/partnerlogos/' + user.image;
    }
    this.navbarBrandText = {icon: '', text: 'Your Company'};
    if (user.roles.includes('org-admin') && user.organization) {
      this.navItems = this.navItems.concat(navItems);
      defaultPage = '/dashboard';
    }
    if (user.roles.includes('partner-admin') && user.organization) {
      this.navItems = this.navItems.concat(paNavItems);
      if (!defaultPage) {
        defaultPage = '/partner/dashboard';
      }
    }
    if (user.roles.includes('admin')) {
      this.navItems = this.navItems.concat(saNavItems);
      if (!defaultPage) {
        defaultPage = '/sa/dashboard';
      }
    }
    if (defaultPage) {
      this.router.navigate( [defaultPage]);
    }
  }

  logout() {
    sessionStorage.setItem('id_token', 'nothing');
    this.oAuthService.logOut(false);
  }

  ngOnDestroy(): void {
    this.changes.disconnect();
  }
}
