import { Component } from '@angular/core';

@Component({
  selector: 'app-waiting',
  template: `<div class="activity d-flex">
      <div class="spinner-border text-light m-auto" role="status" >
          <span class="sr-only">Loading...</span>
      </div>
  </div>`,
  styles: [`.activity {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      background-color: rgba(0,0,0,0.5);
  }
  `]
})
export class WaitingComponent {

  constructor() { }

}
