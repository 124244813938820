import { Component, OnInit } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import {AuthConfig, JwksValidationHandler, OAuthService} from 'angular-oauth2-oidc';
import {environment} from '../environments/environment';

@Component({
  // tslint:disable-next-line
  selector: 'body',
  template: '<router-outlet></router-outlet>'
})
export class AppComponent implements OnInit {

  private sdConfig: AuthConfig = {
    redirectUri: window.location.origin + '/',
    responseType: 'code',
    clientId: environment.oauthClientId,
    requireHttps: false,
    scope: 'openid profile email read write',
    loginUrl: 'https://api.standata.com/oauth/authorize',
    tokenEndpoint: 'https://api.standata.com/oauth/token',
    logoutUrl: 'https://api.standata.com/oauth/logout?redirect_uri=' +
      encodeURIComponent(window.location.origin + '/'),
    oidc: false,
    showDebugInformation: false
  };

  constructor(private router: Router, private oAuthService: OAuthService) { }

  ngOnInit() {
    this.router.events.subscribe((evt) => {
      if (!(evt instanceof NavigationEnd)) {
        return;
      }
      window.scrollTo(0, 0);
    });
    this.configure();
  }

  private configure() {
    this.oAuthService.configure(this.sdConfig);
    this.oAuthService.setupAutomaticSilentRefresh();
    // this.oAuthService.tokenValidationHandler = new JwksValidationHandler();
    this.oAuthService.tryLoginCodeFlow()
      .then(() => {
        // console.log('oauth logged in');
      })
      .catch(err => {
        console.log(err);
      });
  }
}
