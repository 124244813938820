import {Injectable, NgModule, OnDestroy} from '@angular/core';
import { Routes, RouterModule, Router, CanActivate } from '@angular/router';

// Import Containers
import { DefaultLayoutComponent } from './containers';

import { P404Component } from './views/error/404.component';
import { P500Component } from './views/error/500.component';
import { LoginComponent } from './views/login/login.component';
import { RegisterComponent } from './views/register/register.component';
import { ProfileComponent } from './views/profile/profile.component';
import {UserService} from './user.service';
import {User} from './models/user';
import {Subscription} from 'rxjs';
import {OAuthService} from 'angular-oauth2-oidc';
import {WaitingComponent} from './views/waiting/waiting';

@Injectable()
export class NoOrgAdminGuard implements CanActivate, OnDestroy {
  private user: User;
  private userSubscription: Subscription;
  constructor(private router: Router, private userService: UserService, private oAuthService: OAuthService) {
    this.userSubscription = this.userService.getUser().subscribe((user) => {
      this.user = user;
    });
  }

  ngOnDestroy(): void {
    this.userSubscription.unsubscribe();
  }

  canActivate() {
    if (this.oAuthService.hasValidAccessToken()) {
      // authenticated, check roles
      console.log('authenticated');
      if (this.user) {
        if (
          this.user.roles.includes('admin') ||
          this.user.roles.includes('org-admin') ||
          this.user.roles.includes('partner-admin')
        ) {
          return true;
        }
      }
      console.log('but no user');
      // wait for user to be refreshed
      this.router.navigate(['/waiting']);
      return false;
    }
    this.oAuthService.initCodeFlow();
    return false;
  }

}

export const routes: Routes = [
  /*{
    path: '',
    redirectTo: 'dashboard',
    pathMatch: 'full',
  },*/
  {
    path: 'waiting',
    component: WaitingComponent
  },
  {
    path: '404',
    component: P404Component,
    data: {
      title: 'Page 404'
    }
  },
  {
    path: '500',
    component: P500Component,
    data: {
      title: 'Page 500'
    }
  },
  {
    path: 'login',
    component: LoginComponent,
    data: {
      title: 'Login Page'
    }
  },
  {
    path: 'register',
    component: RegisterComponent,
    data: {
      title: 'Register Page'
    }
  },
  {
    path: '',
    component: DefaultLayoutComponent,
    data: {
      title: 'Home'
    },
    children: [
      {
        path: '',
        loadChildren: './views/orgadmin/org-admin.module#OrgAdminModule',
        canActivate: [NoOrgAdminGuard]
      },
      {
        path: 'base',
        loadChildren: './views/base/base.module#BaseModule'
      },
      {
        path: 'buttons',
        loadChildren: './views/buttons/buttons.module#ButtonsModule'
      },
      {
        path: 'charts',
        loadChildren: './views/chartjs/chartjs.module#ChartJSModule'
      },
      {
        path: 'icons',
        loadChildren: './views/icons/icons.module#IconsModule'
      },
      {
        path: 'notifications',
        loadChildren: './views/notifications/notifications.module#NotificationsModule'
      },
      {
        path: 'theme',
        loadChildren: './views/theme/theme.module#ThemeModule'
      },
      {
        path: 'widgets',
        loadChildren: './views/widgets/widgets.module#WidgetsModule'
      },
      {
        path: 'profile',
        component: ProfileComponent,
        data: {
          title: 'Profile'
        }
      },
      {
        path: 'partner',
        loadChildren: './views/partneradmin/partner-admin.module#PartnerAdminModule'
      },
      {
        path: 'sa',
        loadChildren: './views/superadmin/super-admin.module#SuperAdminModule'
      },
    ]
  },
  { path: '**', component: P404Component }
];

@NgModule({
  imports: [ RouterModule.forRoot(routes) ],
  exports: [ RouterModule ],
  providers: [
      NoOrgAdminGuard
  ]
})
export class AppRoutingModule {}


