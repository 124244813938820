import { Component, OnInit, NgZone, ChangeDetectorRef } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-dashboard',
  templateUrl: 'login.component.html'
})
export class LoginComponent implements OnInit {

  username: string;
  password: string;
  isLoading: boolean;

  constructor(private router: Router, private ngZone: NgZone, private cd: ChangeDetectorRef) { }

  ngOnInit() {
    this.isLoading = false;
  }

  login() {
    this.isLoading = true;
  }

  tryLogin() {
    console.log('attemtping log in');
  }
}


