export interface NavData {
    name?: string;
    url?: string;
    icon?: string;
    badge?: any;
    title?: boolean;
    children?: any;
    variant?: string;
    attributes?: object;
    divider?: boolean;
    class?: string;
}

export const saNavItems: NavData[] = [
    {
        divider: true,
        attributes: {'role': 'admin'}
    },
    {
        title: true,
        name: 'Super Admin',
    },
    {
        name: 'Dashboard',
        url: '/sa/dashboard',
        icon: 'fa fa-dashboard'
    },
    {
        name: 'Organizations',
        url: '/sa/organizations',
        icon: 'fa fa-sitemap'
    },
    {
        name: 'Surveys',
        url: '/sa/surveys',
        icon: 'fa fa-file-text'
    },
    {
        name: 'Users',
        url: '/sa/users',
        icon: 'fa fa-user-circle'
    },
    {
        name: 'Partners',
        url: '/sa/partners',
        icon: 'fa fa-handshake-o'
    },
    {
        name: 'Dongles',
        url: '/sa/dongles',
        icon: 'fa fa-bluetooth'
    },
];
