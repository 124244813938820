import {Injectable} from '@angular/core';
import {HttpClient, HttpErrorResponse} from '@angular/common/http';
import {OAuthService} from 'angular-oauth2-oidc';
import {Observable, throwError} from 'rxjs';
import {catchError} from 'rxjs/operators';
import {User} from './models/user';
import * as moment from 'moment';
import {Organization} from './models/organization';
import {Dongle} from './models/dongle';
import {Partner} from './models/partner';
import {Team} from './models/team';
import {Desk} from './models/desk';
import {Location} from './models/location';

@Injectable({
  providedIn: 'root'
})

export class ApiService {
  private apiUrl = 'https://api.standata.com/api/';
  private oAuthUrl = 'https://api.standata.com/oauth/';

  constructor(private http: HttpClient, private oAuthService: OAuthService) {}

  getUser(): Observable<any> {
    return this.http
      .get(this.apiUrl + 'user')
      .pipe(catchError(this.handleError));
  }

  getUserById(userId: number): Observable<any> {
    return this.http
      .get(this.apiUrl + 'users/' + userId)
      .pipe(catchError(this.handleError));
  }

  updateUsers(users: any[]): Observable<any> {
    return this.http
      .put(this.apiUrl + 'users', users)
      .pipe(catchError(this.handleError));
  }

  getUsersSearch(limit: number, offset: number, sortColumn: string, sortDirection: string,
                 orgIds?: number[], searchTerm?: string): Observable<any> {
    let qs = '?limit=' + limit + '&offset=' + offset + '&sortColumn=' + sortColumn + '&sortDirection=' + sortDirection;
    if (orgIds) {
      qs += '&orgIds=' + JSON.stringify(orgIds);
    }
    if (searchTerm) {
      qs += '&searchTerm=' + searchTerm;
    }
    return this.http
      .get(this.apiUrl + 'users' + qs)
      .pipe(catchError(this.handleError));
  }

  createUser(user: User): Observable<any> {
    return this.http
      .post(this.apiUrl + 'user', user);
  }

  getUserDailyTransitions(startDate: moment.Moment, endDate: moment.Moment,
                          orgId?: number, teamIds?: number[],
                          limit?: number, offset?: number,
                          sortColumn?: string, sortDirection?: string,
                          searchTerm?: string): Observable<any> {
    let qs = '?startDate=' + startDate.format('YYYY-MM-DD') + '&endDate=' + endDate.format('YYYY-MM-DD');
    if (orgId) {
      qs += '&orgId=' + orgId;
    }
    if (teamIds) {
      qs += '&teamIds=' + JSON.stringify(teamIds);
    }
    if (limit) {
      qs += '&limit=' + limit + '&offset=' + offset;
    }
    if (sortColumn && sortDirection) {
      qs += '&sortColumn=' + sortColumn + '&sortDirection=' + sortDirection;
    }
    if (searchTerm) {
      qs += '&searchTerm=' + searchTerm;
    }
    return this.http
      .get(this.apiUrl + 'users/daily-transitions' + qs)
      .pipe(catchError(this.handleError));
  }

  getUserCount(orgId?: number): Observable<any> {
    let qs = '';
    if (orgId) {
      qs += '?orgId=' + orgId;
    }
    return this.http
      .get(this.apiUrl + 'users/count' + qs)
      .pipe(catchError(this.handleError));
  }

  getTeams(limit: number, offset: number, sortColumn: string, sortDirection: string,
           orgId: number, searchTerm?: string): Observable<any> {
    let qs = '?limit=' + limit + '&offset=' + offset + '&sortColumn=' + sortColumn + '&sortDirection=' + sortDirection;
    qs += '&orgId=' + orgId;
    if (searchTerm) {
      qs += '&searchTerm=' + searchTerm;
    }
    return this.http
      .get(this.apiUrl + 'teams' + qs)
      .pipe(catchError(this.handleError));
  }

  createOrUpdateTeam(team: Team): Observable<any> {
    if (team.teamId) {
      return this.http
        .put(this.apiUrl + 'teams', team)
        .pipe(catchError(this.handleError));
    } else {
      return this.http
        .post(this.apiUrl + 'teams', team)
        .pipe(catchError(this.handleError));
    }
  }

  deleteTeam(teamId: number) {
    return this.http
      .delete(this.apiUrl + 'teams/' + teamId)
      .pipe(catchError(this.handleError));
  }

  createUserTeams(user: User) {
    return this.http
      .post(this.apiUrl + 'users/teams', user)
      .pipe(catchError(this.handleError));
  }

  getUsersByOrgId(orgId: number, users?: User[]): Observable<any> {
    return this.http
      .get(this.apiUrl + 'user/' + orgId)
      .pipe(catchError(this.handleError));
  }

  getOrganizaiton(orgId: number): Observable<any> {
    return this.http
      .get(this.apiUrl + 'org/' + orgId)
      .pipe(catchError(this.handleError));
  }

  getOrganizaitonsByPartnerId(partnerId: number, sortColumn?: string, sortDirection?: string,
                              limit?: number, skip?: number): Observable<any> {
    return this.http
      .get(this.apiUrl + 'org/' + partnerId)
      .pipe(catchError(this.handleError));
  }

  deleteOrganization(orgId: number) {
    return this.http
      .delete(this.apiUrl + 'organizations/' + orgId)
      .pipe(catchError(this.handleError));
  }

  getEventStatsByUserCollection(users: User[], startDate: moment.Moment, endDate: moment.Moment): Observable<any> {
    return this.http
      .get(this.apiUrl + 'events/' + users.join(','))
      .pipe(catchError(this.handleError));
  }

  getOrganizations(): Observable<any> {
    return this.http
      .get(this.apiUrl + 'organizations')
      .pipe(catchError(this.handleError));
  }

  getOrganizationsSearch(limit: number, offset: number,
                         sortColumn: string, sortDirection: string, searchTerm?: string): Observable<any> {
    let qs = '?limit=' + limit + '&offset=' + offset + '&sortColumn=' + sortColumn + '&sortDirection=' + sortDirection;
    if (searchTerm) {
      qs += '&searchTerm=' + searchTerm;
    }
    return this.http
      .get(this.apiUrl + 'organizations' + qs)
      .pipe(catchError(this.handleError));
  }

  createOrUpdateOrganization(organization: Organization): Observable<any> {
    if (organization.orgId) {
      return this.http
        .put(this.apiUrl + 'organizations', organization)
        .pipe(catchError(this.handleError));
    } else {
      return this.http
        .post(this.apiUrl + 'organizations', organization)
        .pipe(catchError(this.handleError));
    }
  }

  getPlatforms(users: User[]): Observable<any> {
    return this.http
      .get(this.apiUrl + 'platform/' + users.join(','))
      .pipe(catchError(this.handleError));
  }

  getSurveys() {
    return this.http
      .get(this.apiUrl + 'surveys')
      .pipe(catchError(this.handleError));
  }

  getSurveyQuestions(surveyQuestionId: number): Observable<any> {
    return this.http
      .get(this.apiUrl + 'survey-question/' + surveyQuestionId)
      .pipe(catchError(this.handleError));
  }

  getSurveyResponsesByUser(userId: number) {
    return this.http
      .get(this.apiUrl + '/survey_response?userId=' + userId)
      .pipe(catchError(this.handleError));
  }

  getPartners(): Observable<any> {
    return this.http
      .get(this.apiUrl + 'partners')
      .pipe(catchError(this.handleError));
  }

  getPartnersSearch(limit: number, offset: number,
                    sortColumn: string, sortDirection: string, searchTerm?: string): Observable<any> {
    let qs = '?limit=' + limit + '&offset=' + offset + '&sortColumn=' + sortColumn + '&sortDirection=' + sortDirection;
    if (searchTerm) {
      qs += '&searchTerm=' + searchTerm;
    }
    return this.http
      .get(this.apiUrl + 'partners' + qs)
      .pipe(catchError(this.handleError));
  }

  createOrUpdatePartner(partner: Partner): Observable<any> {
    if (partner.partnerId) {
      return this.http
        .put(this.apiUrl + 'partners', partner)
        .pipe(catchError(this.handleError));
    } else {
      return this.http
        .post(this.apiUrl + 'partners', partner)
        .pipe(catchError(this.handleError));
    }
  }

  deletePartner(partnerId: number) {
    return this.http
      .get(this.apiUrl + 'partners/' + partnerId)
      .pipe(catchError(this.handleError));
  }

  createOrUpdateDongle(dongle: Dongle): Observable<any> {
    if (dongle.dongleId) {
      return this.http
        .put(this.apiUrl + 'dongle', dongle)
        .pipe(catchError(this.handleError));
    } else {
      return this.http
        .post(this.apiUrl + 'dongle', dongle)
        .pipe(catchError(this.handleError));
    }
  }

  getDonglesSearch(limit: number, skip: number,
                   sortColumn: string, sortDirection: string, searchTerm: string, partnerId?: number): Observable<any> {
    const qs = '?limit=' + limit + '&skip=' + skip;
    return this.http
      .get(this.apiUrl + 'dongle' + qs)
      .pipe(catchError(this.handleError));
  }

  deleteDongle(dongleId: number) {
    return this.http
      .delete(this.apiUrl + 'dongle/' + dongleId)
      .pipe(catchError(this.handleError));
  }

  getDesksSearch(limit: number, offset: number,
                   sortColumn: string, sortDirection: string, searchTerm: string,
                   orgId: number, locationId?: number): Observable<any> {
    let qs = '?limit=' + limit + '&offset=' + offset + '&sortColumn=' + sortColumn + '&sortDirection=' + sortDirection;
    qs += '&orgId=' + orgId;
    if (searchTerm) {
      qs += '&searchTerm=' + searchTerm;
    }
    if (locationId) {
      qs += '&locationId=' + locationId;
    }
    return this.http
      .get(this.apiUrl + 'desks' + qs)
      .pipe(catchError(this.handleError));
  }

  createOrUpdateDesk(desk: Desk): Observable<any> {
    if (desk.deskId) {
      return this.http
        .put(this.apiUrl + 'desks', desk)
        .pipe(catchError(this.handleError));
    } else {
      return this.http
        .post(this.apiUrl + 'desks', desk)
        .pipe(catchError(this.handleError));
    }
  }

  deleteDesk(deskId: number) {
    return this.http
      .delete(this.apiUrl + 'desks/' + deskId)
      .pipe(catchError(this.handleError));
  }

  getLocationsSearch(limit: number, offset: number,
                 sortColumn: string, sortDirection: string, searchTerm: string,
                 orgId: number): Observable<any> {
    let qs = '?limit=' + limit + '&offset=' + offset + '&sortColumn=' + sortColumn + '&sortDirection=' + sortDirection;
    qs += '&orgId=' + orgId;
    if (searchTerm) {
      qs += '&searchTerm=' + searchTerm;
    }
    return this.http
      .get(this.apiUrl + 'locations' + qs)
      .pipe(catchError(this.handleError));
  }

  createOrUpdateLocation(location: Location): Observable<any> {
    if (location.locationId) {
      return this.http
        .put(this.apiUrl + 'locations', location)
        .pipe(catchError(this.handleError));
    } else {
      return this.http
        .post(this.apiUrl + 'locations', location)
        .pipe(catchError(this.handleError));
    }
  }

  deleteLocation(locationId: number) {
    return this.http
      .delete(this.apiUrl + 'locations/' + locationId)
      .pipe(catchError(this.handleError));
  }


  private handleError(error: HttpErrorResponse) {
    if (error.status === 401) {
      console.log('401 Unauthorized Error');
      console.log(this.oAuthService);
    }
    return throwError('There was an error with the request.');
  }
}
