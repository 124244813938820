export interface NavData {
    name?: string;
    url?: string;
    icon?: string;
    badge?: any;
    title?: boolean;
    children?: any;
    variant?: string;
    attributes?: object;
    divider?: boolean;
    class?: string;
}

export const paNavItems: NavData[] = [
    {
        divider: true,
        attributes: {'role': 'partner admin'}
    },
    {
        title: true,
        name: 'Partner Admin',
    },
    {
        name: 'Partner Dashboard',
        url: '/partner/dashboard',
        icon: 'fa fa-dashboard'
    },
    {
        name: 'Organizations',
        url: '/partner/organizations',
        icon: 'fa fa-sitemap'
    },
    {
        name: 'Users',
        url: '/partner/users',
        icon: 'fa fa-user-circle'
    },
];
