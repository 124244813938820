import {Injectable, NgZone} from '@angular/core';
import {BehaviorSubject, ObjectUnsubscribedError, Observable} from 'rxjs';
import {HttpClient} from '@angular/common/http';
import {OAuthService} from 'angular-oauth2-oidc';
import {ApiService} from './api.service';
import {User} from './models/user';
import {Router} from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class UserService {
  private user: User;
  private userSubject: BehaviorSubject<User> = new BehaviorSubject(null);
  public getUser(): Observable<User> {
    return this.userSubject.asObservable();
  }

  public async getUserPromise(): Promise<User> {
    return new Promise(async (resolve, reject) => {
      if (this.user) {
        resolve(this.user);
      } else {
        await this.refreshUser();
        resolve(this.user);
      }
    });
  }

  constructor(private http: HttpClient, private ngZone: NgZone,
              private oAuthService: OAuthService, private apiService: ApiService, private router: Router) {
    this.refreshUser();
  }
  async refreshUser() {
    this.user = await this.apiService.getUser().toPromise();
    this.userSubject.next(this.user);
    if (this.user && this.user.roles.includes('admin')) {
      this.router.navigate(['/']);
    }
  }
}
